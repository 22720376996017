import React from "react"
const VideoWrapper = ({ id, video, videoEvent }) => (
  <video
    muted
    controls
    preload="metadata"
    id={id}
    onPlay={() => {
      if (videoEvent) {
        videoEvent(true)
      }
    }}
    onPause={() => {
      if (videoEvent) {
        videoEvent(false)
      }
    }}
  >
    <source src={`${video}#t=0.1`} type="video/mp4" />
  </video>
)

export default VideoWrapper
