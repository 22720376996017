import { downloadAction } from "./download"

export function bridge(payload) {
  try {
    window.webkit.messageHandlers.bridge.postMessage(JSON.stringify(payload))
  } catch {
    downloadAction()
  }
}

// TODO: for de som ikke har/er i appen
export function bridgeAction(action) {
  bridge({ action })
}

export function playVideo(id, scrollToId) {
  const video = document.getElementById(`video_${id}`)
  if (video) {
    if (video.paused) {
      const scrollToEl = document.getElementById(scrollToId)
      if (scrollToEl) {
        scrollToEl.scrollIntoView({ behavior: "smooth" })
      }
      video.play()
    } else {
      video.pause()
    }
  }
}

export function playVideoTitle(isPlaying, title) {
  return isPlaying ? `${title} (spiller av...)` : title
}
