import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const FeatureMenu = ({ data, except }) => {
  return (
    <>
      {except !== "capture" && (
        <Link to="/features/capture" className="invisible-link">
          <section
            className="section-button"
            style={{ borderColor: "#F4EBD9" }}
          >
            <h2>
              <Img
                fixed={data.iconClipboard.childImageSharp.fixed}
                alt={"Tavle"}
                className="featured-icon"
              />{" "}
              Huske ting lettere
            </h2>
            <p>
              Få kontroll på alle løse tråder, ideer og oppgaver som dukker opp
              gjennom dagen
            </p>
          </section>
        </Link>
      )}

      {except !== "organize" && (
        <Link to="/features/organize" className="invisible-link">
          <section
            className="section-button"
            style={{ borderColor: "#F4DBD9" }}
          >
            <h2>
              <Img
                fixed={data.iconShuffle.childImageSharp.fixed}
                alt={"Piler"}
                className="featured-icon"
              />{" "}
              Få orden og struktur
            </h2>
            <p>Stress ned med oversikt og kontroll på alt som må gjøres</p>
          </section>
        </Link>
      )}

      {except !== "routines" && (
        <Link to="/features/routines" className="invisible-link">
          <section
            className="section-button"
            style={{ borderColor: "#D0F1D1" }}
          >
            <h2>
              <Img
                fixed={data.iconRepeat.childImageSharp.fixed}
                alt={"Piler i sirkel"}
                className="featured-icon"
              />{" "}
              Bygge gode rutiner
            </h2>
            <p>
              Ligg i forkant av ting med en morgenrutine, kveldsrutine og
              ukentlig rutine
            </p>
          </section>
        </Link>
      )}

      {except !== "reflection" && (
        <Link to="/features/reflection" className="invisible-link">
          <section
            className="section-button"
            style={{ borderColor: "#F4EBD9" }}
          >
            <h2>
              <Img
                fixed={data.iconCompass.childImageSharp.fixed}
                alt={"Kompass"}
                className="featured-icon"
              />{" "}
              Work-life balance
            </h2>
            <p>Reflekter over små og store ting</p>
          </section>
        </Link>
      )}

      {except !== "countup" && (
        <Link to="/features/countup" className="invisible-link">
          <section
            className="section-button"
            style={{ borderColor: "#E7E1F3" }}
          >
            <h2>
              <Img
                fixed={data.iconClock.childImageSharp.fixed}
                alt={"Klokke"}
                className="featured-icon"
              />{" "}
              Følge opp og vedlikeholde ting
            </h2>
            <p>
              Fra egentid, sosialt, venner og familie til forsikringer og
              vasking
            </p>
          </section>
        </Link>
      )}
    </>
  )
}

const WrappedFeatureMenu = (props) => (
  <StaticQuery
    query={graphql`
      query {
        iconClipboard: file(relativePath: { eq: "icon-clipboard.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconShuffle: file(relativePath: { eq: "icon-shuffle.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconClock: file(relativePath: { eq: "icon-clock.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconCompass: file(relativePath: { eq: "icon-compass.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iconRepeat: file(relativePath: { eq: "icon-repeat.png" }) {
          childImageSharp {
            fixed(width: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureFooter: file(relativePath: { eq: "feature-footer.png" }) {
          childImageSharp {
            fluid(maxWidth: 397) {
              ...GatsbyImageSharpFluid_noBase64
              presentationWidth
            }
          }
        }
      }
    `}
    render={(data) => <FeatureMenu data={data} {...props} />}
  />
)

export default WrappedFeatureMenu;
