import React from "react"
import FeatureMenu from "./featureMenu"

const AlsoHelpsMenu = ({ except, title }) => {
  return (
    <>
      <section
        className="full-width inapp-invisible"
        style={{ marginTop: "150px" }}
      >
        <div className="grid full-width-grid">
          <div className="full-width-grid" style={{ textAlign: "center" }}>
            <h2
              className="feature-group-title"
              style={{ marginBottom: "3rem" }}
            >
              {title ?? "Mente hjelper deg også med:"}
            </h2>
          </div>
        </div>
      </section>
      <div className="inapp-invisible">
        <FeatureMenu except={except} />
      </div>
    </>
  )
}

export default AlsoHelpsMenu
